@import "_bs";
@import "@fortawesome/fontawesome-free/css/all.css";

.navbar {
  background-color: #0075B0;
}

.navbar-dark .navbar-nav .nav-link, .navbar-dark .navbar-nav .nav-text {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.9);
}

body {
  background-color: #fcfcfc;
}

main h1 {
  font-size: 1.7em;
  margin-bottom: 1.2rem;
}

main h2 {
  font-size: 1.4em;
}

main h3 {
  font-size: 1.2em;
}

main.container {
  padding-bottom: 3em;
}

.maintenance-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
};

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: $primary;
  border-color: $primary;
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: rgba(0, 0, 0, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: inherit;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .form-select:valid, .form-select.is-valid {
  border-color: #ced4da;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
  padding-right: 0.75rem;
  background-image: initial;
  background-repeat: initial;
  background-position: initial;
  background-size: initial;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 0.75rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus,
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #80bad8;
  box-shadow: 0 0 0 .25rem rgba(0, 117, 176, 0.25);
}

.fas-alternate {
  font-weight: 400;
}

.center {
  text-align: center;
}

.hidden {
  display: none !important;
}

.smalltext {
  font-size: 0.8rem;
}

.italic {
  font-style: italic;
}

.label-required::after {
  color: $danger;
  content: '*';
}

textarea.noresize {
  resize: none;
}

.mono {
  font-family: monospace;
}
